<template>
  <div>
    <b-row style="margin-bottom: 20px;">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" md="8">
        <strong style="font-size: 30px; font-family: cursive;">Woncology-vue v{{ d_version }}</strong>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'version_general_v2_0_1'}"></work-subject>
        <hr>
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'codedeveloperinfo'}"></work-subject>
        <hr>
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'install'}"></work-subject>
        <hr>
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'license_info'}"></work-subject>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WorkSubject } from '@/components/widgets/WorkSubject';
import { version } from '@/config';
import { mapGetters } from 'vuex';
export default {
  name: 'Welcome',
  computed: mapGetters({
    // StoreLang: 'StoreLang',
  }),
  components: {
    WorkSubject
  },
  props: {},
  data () {
    return {
      d_version: version,
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_calculateChartStyle: function (chart) {
      let style = 'margin: 40px; float: left;';
      if (chart.style) {
        for (let i in chart.style) {
          style += i + ': ' + chart.style[i] + ';';
        }
        if (chart.style.minHeight) {
          style += 'min-height: ' + chart.style.minHeight;
        } else if (chart.style.height) {
          style += 'min-height: ' + chart.style.height;
        }
      } else {
        style += 'width: 300px;';
      }
      if (style.indexOf('min-height') === -1) {
        style += 'min-height: 300px;';
      }
      return style;
    },
    f_calculateChartStyleLong: function (chart) {
      let style = 'margin: 10px; height: 500px; width: 1000px;';
      return style;
    },
    f_goToThisPage: function () {
      this.$router.push({ path: '/project', query: {} });
    },
    f_goToMonitorPage: function () {
      this.$router.push({ path: '/monitor', query: {} });
    },
    f_goToLogPage: function () {
      this.$router.push({ path: '/log', query: {} });
    },
  },
  watch: {}
}

</script>

